<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="8">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Basic</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.basicForm.openForm(litter)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Basic Details</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Breed</th>
                  <td>
                    <div v-if="litter.breed">{{ litter.breed.name }}</div>
                  </td>

                  <th>Grade</th>
                  <td>{{ litter.grade }}</td>
                </tr>

                <tr>
                  <th>D.O.B</th>
                  <td>
                    <div v-if="litter.dob">{{ formatDate(litter.dob) }}</div>
                  </td>
                  <th>Size</th>
                  <td>
                    <div v-if="litter.size">{{ litter.size.name }}</div>
                  </td>
                </tr>

                <tr>
                  <th>Age</th>
                  <td>
                    <div v-if="litter.dob">{{ this.age(litter.dob) }}</div>
                  </td>
                  <th>Colour</th>
                  <td>
                    <div v-if="litter.colors">
                      {{
                        litter.colors.map((c) => c.breed_colour.name).join(", ")
                      }}
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>
                    # Puppies
                    <span
                      v-if="
                        litter.status == 'Planned' || litter.status == 'Due'
                      "
                      >(Predicted)</span
                    >
                  </th>

                  <td>
                    <div
                      v-if="
                        litter.status != 'Planned' && litter.status != 'Due'
                      "
                    >
                      {{ litter.puppies.length }}
                    </div>
                    <div v-else>{{ litter.predicted }}</div>
                  </td>
                  <th>Markings</th>
                  <td>
                    <div v-if="litter.marking">{{ litter.marking.name }}</div>
                  </td>
                </tr>

                <tr>
                  <th
                    v-if="litter.status != 'Planned' && litter.status != 'Due'"
                  >
                    # Girls
                  </th>
                  <td
                    v-if="litter.status != 'Planned' && litter.status != 'Due'"
                  >
                    <div>{{ countPuppiesGender("F").length }}</div>
                  </td>
                  <th>Coat Type</th>
                  <td>
                    <div v-if="litter.coat_type_id">
                      {{ litter.coat_type.name }}
                    </div>
                  </td>
                </tr>

                <tr v-if="litter.status != 'Planned' && litter.status != 'Due'">
                  <th># Boys</th>
                  <td>
                    <div>{{ countPuppiesGender("M").length }}</div>
                  </td>
                  <th># Available</th>
                  <td>
                    {{
                      litter.puppies.length - litter.applications.length > 0
                        ? litter.puppies.length - litter.applications.length
                        : 0
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-actions>
              <v-btn
                small
                depressed
                class="mr-2"
                color="green lighten-4 green--text"
                @click="openChangeStatus('Completed')"
              >
                Change to Completed
              </v-btn>

              <!-- <v-btn
                small
                depressed
                class="mr-2"
                color="green lighten-4 green--text"
                @click="openChangeStatus('Due')"
              >
                Change to Due
              </v-btn>

              <v-btn
                small
                depressed
                class="mr-2"
                color="green lighten-4 green--text"
                @click="openChangeStatus('Born')"
              >
                Change to Born
              </v-btn>-->

              <v-btn
                small
                depressed
                class="mr-2"
                color="red lighten-4 red--text"
                @click="openChangeStatus('Failed')"
              >
                Change to Failed
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Sire</div>

              <v-tooltip bottom v-if="litter.status == 'Planned'">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.sireForm.openForm(litter)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Change Sire</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <div v-if="litter.sire">
              <router-link
                :to="{
                  name: 'module-lhl-dogs-individual',
                  params: { dogId: litter.sire.id },
                }"
              >
                <v-img
                  v-if="litter.sire.profile_image"
                  :src="litter.sire.profile.asset_urls.url"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                >
                  <v-card-title>{{ litter.sire.name }}</v-card-title>
                </v-img>

                <v-img
                  v-else
                  src="@/assets/image-placeholder.svg"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                >
                  <v-card-title>{{ litter.sire.name }}</v-card-title>
                </v-img>
              </router-link>
            </div>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Dam</div>

              <!--<v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.damForm.openForm(litter)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Basic Details</span>
              </v-tooltip>-->
            </v-card-title>
            <v-divider></v-divider>

            <div v-if="litter.dam">
              <router-link
                :to="{
                  name: 'module-lhl-dogs-individual',
                  params: { dogId: litter.dam.id },
                }"
              >
                <v-img
                  v-if="litter.dam.profile_image"
                  :src="litter.dam.profile.asset_urls.url"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                >
                  <v-card-title>{{ litter.dam.name }}</v-card-title>
                </v-img>
                <v-img
                  v-else
                  src="@/assets/image-placeholder.svg"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                >
                  <v-card-title>{{ litter.dam.name }}</v-card-title>
                </v-img>
              </router-link>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <BasicForm ref="basicForm" :litter="litter" />
      <SireForm ref="sireForm" />
      <DamForm ref="damForm" />

      <v-dialog
        scrollable
        v-model="changeStatus.dialog"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">Change Status</v-card-title>
          <v-card-text>Are you sure you wish to change status?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetChangeStatus"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="changeStatus.loading"
              @click="saveChangeStatus"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import BasicForm from "../components/BasicForm";
import SireForm from "../components/basic/SireForm";
import DamForm from "../components/basic/DamForm";
import moment from "moment";

export default {
  components: {
    BasicForm,
    SireForm,
    DamForm,
  },

  data() {
    return {
      fields: {
        status: null,
      },
      changeStatus: {
        dialog: false,
        litter: {},
        status: null,
        loading: false,
      },
    };
  },

  methods: {
    countPuppiesGender(gender) {
      let puppies = this.litter.puppies.filter((c) => c.gender == gender);

      return puppies;
    },

    age(dob) {
      var b = moment(dob, "YYYY-MM-DD HH:mm:ss");
      var a = moment(new Date(), "YYYY-MM-DD HH:mm:ss");

      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");

      return years + " years " + months + " months " + days + " days";
    },

    openChangeStatus(status) {
      this.changeStatus.status = status;
      this.changeStatus.dialog = true;
    },

    resetChangeStatus() {
      this.changeStatus.dialog = false;
      this.changeStatus.status = null;
      this.changeStatus.loading = false;
    },

    saveChangeStatus() {
      this.changeStatus.loading = true;

      this.fields.status = this.changeStatus.status;

      this.$store
        .dispatch("lhl/litters/changeStatus", {
          appId: this.$route.params.id,
          litterId: this.$route.params.litterId,

          fields: this.fields,
        })
        .then(() => {
          this.resetChangeStatus();
        })
        .catch(() => {
          this.changeStatus.loading = false;
        });
    },
  },

  computed: {
    litter() {
      return this.$store.getters["lhl/litters/get"];
    },
  },
};
</script>
